import { Component, DestroyRef, Inject, OnInit, signal, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent, ToggleButtonsComponent } from '@cumlaude/shared-components-buttons';
import { InstellingBron, InstellingETLStatus, RCumLaudeAccount, RInstelling, RInstellingAdditionalObjectKey } from '@cumlaude/service-contract';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';
import { InstellingLabelsComponent } from '../../components/instelling-labels/instelling-labels.component';
import { LicentiesCardComponent } from './licenties-card.component';
import { ModulesCardComponent } from './modules-card.component';
import { accountLabelMetAfkorting, compareDates, getDatum, includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-instelling-page',
	templateUrl: './instelling-page.component.html',
	styleUrl: './instelling-page.component.scss',
	imports: [
		AsyncPipe,
		ButtonComponent,
		InstellingLabelsComponent,
		LicentiesCardComponent,
		ModulesCardComponent,
		DatePipe,
		FormDropdownComponent,
		ConfirmDialogComponent,
		ToggleButtonsComponent,
	],
})
export class InstellingPageComponent implements OnInit {
	etlBezigStatussen: InstellingETLStatus[] = [InstellingETLStatus.GEPLAND, InstellingETLStatus.BEZIG];

	options: string[] = ['Bulk', 'Delta'];

	selectedOption = signal('Delta');

	somtodayUrl: string;

	instelling$!: Observable<RInstelling>;
	refreshInstelling$ = new BehaviorSubject<void>(undefined);

	accountOptions$!: Observable<Option<RCumLaudeAccount>[]>;

	selectedAccount: WritableSignal<RCumLaudeAccount | undefined> = signal(undefined);
	accountZoekFilter = signal('');

	enviromentName: string;
	InstellingBron = InstellingBron;

	private id!: number;
	bestuurId?: number;

	format = 'dd-MM-yy HH:mm';

	@ViewChild('confirmInstellingVerwijderen')
	confirmInstellingVerwijderen!: TemplateRef<{ data: { naam: string } }>;

	@ViewChild('confirmInstellingHeractiveren')
	confirmInstellingHeractiveren!: TemplateRef<{ data: { naam: string; bron: InstellingBron } }>;

	@ViewChild('confirmInstellingDeactiveren')
	confirmInstellingDeactiveren!: TemplateRef<{ data: { naam: string; bron: InstellingBron } }>;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private readonly restService: RestService,
		private readonly destroyRef: DestroyRef,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		this.enviromentName = getFriendlyEnvironmentName(this.envConfig.environmentName);
		this.somtodayUrl = envConfig.somtodayUrl!;
	}

	ngOnInit(): void {
		this.extractInstellingId();
		this.extractBestuurId();

		this.instelling$ = this.refreshInstelling$.pipe(
			switchMap(() =>
				this.restService.getInstelling(this.id, [RInstellingAdditionalObjectKey.LICENTIES, RInstellingAdditionalObjectKey.JOBRUNS])
			),
			shareReplay(1)
		);
		this.accountOptions$ = this.instelling$.pipe(
			switchMap((instelling) => this.restService.getAccountsByInstelling(instelling)),
			map((accounts) => accounts.map(this.mapToOption)),
			shareReplay(1)
		);
		this.accountOptions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((accountOptions) => {
			const supportOption = accountOptions.find((option) => option.value.support);
			this.selectedAccount.set(supportOption?.value);
		});
	}

	private extractInstellingId() {
		const idParam = this.route.snapshot.paramMap.get('id');
		this.id = Number.parseInt(idParam!);
		if (isNaN(this.id)) {
			throw new Error(`Invalid ID: ${idParam}`);
		}
	}

	private extractBestuurId() {
		const bestuurIdParam = this.route.snapshot.queryParamMap.get('bestuur');
		if (bestuurIdParam) this.bestuurId = Number.parseInt(bestuurIdParam);
	}

	goBack() {
		if (this.bestuurId) {
			this.router.navigate(['bestuur', this.bestuurId]);
		} else {
			this.router.navigate(['instelling']);
		}
	}

	activateInstelling(instelling: RInstelling) {
		const dialogRef = this.dialog.open(this.confirmInstellingHeractiveren, {
			data: {
				naam: instelling.naam,
				bron: instelling.bron,
			},
		});
		dialogRef.closed.subscribe((result) => {
			if (result) {
				if (instelling.bron === InstellingBron.Somtoday) return;

				this.restService
					.putInstelling({
						...instelling,
						geldigTot: '9999-12-31',
					})
					.subscribe((result) => {
						if (result) {
							this.toastr.success(`Instelling ${instelling.naam} is geactiveerd.`);
							this.refreshInstelling$.next();
						} else this.toastr.error(`Activeren van instelling ${instelling.naam} is mislukt.`);
					});
			}
		});
	}

	deactivateInstelling(instelling: RInstelling) {
		const dialogRef = this.dialog.open(this.confirmInstellingDeactiveren, {
			data: {
				naam: instelling.naam,
				bron: instelling.bron,
			},
		});
		dialogRef.closed.subscribe((result) => {
			if (result) {
				if (instelling.bron === InstellingBron.Somtoday) return;

				this.restService
					.putInstelling({
						...instelling,
						geldigTot: getDatum(),
					})
					.subscribe((result) => {
						if (result) {
							this.toastr.success(`Instelling ${instelling.naam} is gedeactiveerd.`);
							this.refreshInstelling$.next();
						} else this.toastr.error(`Deactiveren van instelling ${instelling.naam} is mislukt.`);
					});
			}
		});
	}

	deleteInstelling(instelling: RInstelling) {
		const dialogRef = this.dialog.open(this.confirmInstellingVerwijderen, {
			data: { naam: instelling.naam },
		});
		dialogRef.closed.subscribe((result) => {
			if (result) {
				this.restService
					.putInstelling({
						...instelling,
						verwijderd: true,
					})
					.subscribe((result) => {
						if (result) {
							this.toastr.success(`Instelling ${instelling.naam} is verwijderd.`);
							this.goBack();
						} else this.toastr.error(`Verwijderen van instelling ${instelling.naam} is mislukt.`);
					});
			}
		});
	}

	magisterSettings() {
		this.router.navigate(['instelling', this.id, 'settings']);
	}

	isDefaultStartDatum(datum: string): boolean {
		return compareDates('1900-01-01T00:00:00', datum);
	}
	mapToOption(account: RCumLaudeAccount): Option<RCumLaudeAccount> {
		return new Option<RCumLaudeAccount>(account, accountLabelMetAfkorting(account));
	}

	findOption(options: Option<RCumLaudeAccount>[], selected: RCumLaudeAccount | undefined) {
		return options.find((option) => option.value === selected);
	}

	filterOptions(options: Option<RCumLaudeAccount>[], zoekFilter: string) {
		return options.filter((option) => includesIgnoreCaseAndDiacritics(option.text, zoekFilter));
	}

	impersonate(account: RCumLaudeAccount, target: string) {
		const subject = `U/${account.id}`;
		window.open(`${this.envConfig.authUrl}/impersonate?subjectToImpersonate=${encodeURIComponent(subject)}`, target);
	}

	protected readonly InstellingETLStatus = InstellingETLStatus;

	getJobruns(instelling: RInstelling, type: string) {
		const jobrunsAdditional = instelling.additionalObjects![RInstellingAdditionalObjectKey.JOBRUNS];
		const jobruns = type === 'Bulk' ? jobrunsAdditional.bulkJobruns : jobrunsAdditional.deltaJobruns;
		return jobruns.map((jobrun) => {
			return {
				...jobrun,
				doorlooptijd: this.parseInterval(jobrun.doorlooptijd),
				avgDoorlooptijd: this.parseInterval(jobrun.avgDoorlooptijd),
			};
		});
	}

	private parseInterval(doorlooptijd: string | undefined): string {
		if (!doorlooptijd) return '';

		const parts = doorlooptijd.split(':');
		const uren = Number(parts[0]);
		const minuten = Number(parts[1]);
		if (uren > 0) return `${uren} uren en ${minuten} minuten`;
		else return `${minuten} minuten`;
	}
}

import { ChangeDetectionStrategy, Component, computed, Input, OnInit, signal } from '@angular/core';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstellingBron, RInstelling, RMagisterInstelling } from '@cumlaude/service-contract';
import { FormsModule } from '@angular/forms';
import { RestService } from '@cumlaude/shared-services';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { getDatum } from '@cumlaude/shared-utils';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';

@Component({
	selector: 'app-magister-instelling-settings',
	templateUrl: './magister-instelling-settings.component.html',
	styleUrl: './magister-instelling-settings.component.scss',
	imports: [ButtonComponent, FormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagisterInstellingSettingsComponent implements OnInit {
	@Input()
	instelling?: RMagisterInstelling;

	nieuw: boolean = true;

	naam = signal('');
	vaultRecord = signal('');
	isFilled = computed(() => !isEmpty(this.naam()) && !isEmpty(this.vaultRecord()));

	constructor(
		protected restService: RestService,
		protected toastr: ToastrService,
		protected router: Router
	) {}

	ngOnInit() {
		if (this.instelling) {
			this.nieuw = false;
			this.naam.set(this.instelling.naam);
			this.vaultRecord.set(this.instelling.vaultRecord ?? '');
		}
	}

	annuleren() {
		this.gotoInstelling(this.instelling!);
	}

	async toevoegen() {
		try {
			const schoolNaam = this.naam();
			const magisterInstelling = await firstValueFrom(
				this.restService.postMagisterInstelling({
					$type: 'instelling.RMagisterInstelling',
					bron: InstellingBron.Magister,
					modules: [],
					naam: schoolNaam,
					vaultRecord: this.vaultRecord(),
					benchmarkToegestaan: true,
					geldigVanaf: getDatum(),
					geldigTot: '9999-12-31',
					verwijderd: false,
				})
			);
			this.gotoInstelling(magisterInstelling);
			this.toastr.success(`${schoolNaam} is toegevoegd. De omgeving is vanaf morgen (${getDatum('dd-MM', 1)}) beschikbaar.`);
		} catch (err) {
			this.toastr.error(`Er ging iets fout bij het toevoegen van de instelling.`);
		}
	}

	private gotoInstelling(instelling: RInstelling) {
		this.router.navigate(['instelling', instelling.id]);
	}

	async opslaan() {
		try {
			const newInstelling: RMagisterInstelling = {
				...this.instelling!,
				naam: this.naam(),
				vaultRecord: this.vaultRecord(),
			};
			await firstValueFrom(this.restService.putMagisterInstelling(newInstelling));

			this.gotoInstelling(newInstelling);
			this.toastr.success(`Wijzigingen zijn opgeslagen.`);
		} catch (err) {
			this.toastr.error(`Wijzigingen opslaan is mislukt.`);
		}
	}
}

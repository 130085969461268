import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InstellingETLStatus, LicentieRol, RInstelling, RInstellingAdditionalObjectKey } from '@cumlaude/service-contract';
import { InstellingLabelsComponent } from '../instelling-labels/instelling-labels.component';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { isUndefined } from 'lodash-es';
import { addDefined, isDefined } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-instellingen-list',
	templateUrl: './instellingen-list.component.html',
	styleUrl: './instellingen-list.component.scss',
	imports: [InstellingLabelsComponent, CheckboxComponent],
})
export class InstellingenListComponent implements OnInit {
	etlIconStatussen: InstellingETLStatus[] = [InstellingETLStatus.GEPLAND, InstellingETLStatus.BEZIG, InstellingETLStatus.GEFAALD];

	@Input()
	instellingen!: RInstelling[];

	@Input()
	checked?: RInstelling[];

	@Input()
	disabled?: RInstelling[];

	@Input()
	uuidEnabled = false;

	@Input()
	warningIconEnabled = false;

	@Input()
	statusEnabled = false;

	@Input()
	toonBestuurLabel = true;

	@Input()
	licenties: LicentieRol[] = [];

	@Input()
	totaalEnabled = false;

	checkboxEnabled = false;

	@Output()
	instellingClick = new EventEmitter<RInstelling>();

	ngOnInit() {
		this.checkboxEnabled = !isUndefined(this.checked);
	}

	getStatus(instelling: RInstelling): { checked: boolean; disabled: boolean; instellingETLStatus: InstellingETLStatus | undefined } {
		return {
			disabled: this.disabled?.some((target) => target.id === instelling.id) ?? false,
			checked: this.checked?.some((target) => target.id === instelling.id) ?? false,
			instellingETLStatus: instelling.actief && this.warningIconEnabled ? instelling.instellingETLStatus : undefined,
		};
	}

	getLicentieRolLabel(licentieRol: LicentieRol): string {
		const map = {
			[LicentieRol.SCHOOLBREEDTEAM]: 'School',
			[LicentieRol.SECTIE]: 'Sectie',
			[LicentieRol.DOCENTMENTOR]: 'Docent',
			[LicentieRol.BESTUUR]: 'Bestuur',
			[LicentieRol.BESTUURTOTAAL]: 'Bestuur (totaal)',
		};
		return map[licentieRol];
	}

	getLicenties(instelling: RInstelling, rol: LicentieRol): string | undefined {
		const data = instelling.additionalObjects![RInstellingAdditionalObjectKey.LICENTIES][rol];
		if (!isDefined(data)) return undefined;

		if (isDefined(data.totaal) && ![LicentieRol.BESTUUR, LicentieRol.DOCENTMENTOR].includes(rol)) return `${data.inGebruik} / ${data.totaal}`;

		return `${data.inGebruik}`;
	}

	getTotaalLicenties(rol: LicentieRol) {
		const [inGebruik, totaal] = this.instellingen
			.map((instelling) => {
				const data = instelling.additionalObjects![RInstellingAdditionalObjectKey.LICENTIES][rol];
				return [data?.inGebruik, data?.totaal];
			})
			.reduce(([a_g, a_t], [b_g, b_t]) => [addDefined(a_g, b_g), addDefined(a_t, b_t)], [undefined, undefined]);

		if (isDefined(totaal) && ![LicentieRol.BESTUUR, LicentieRol.DOCENTMENTOR].includes(rol)) return `${inGebruik} / ${totaal}`;

		return `${inGebruik ?? 0}`;
	}

	protected readonly InstellingETLStatus = InstellingETLStatus;
	protected readonly LicentieRol = LicentieRol;
}


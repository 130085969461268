<table>
	<thead>
		<tr>
			@if (checkboxEnabled) {
				<th class="checkbox"></th>
			}
			<th>Instelling</th>
			@if (uuidEnabled) {
				<th>UUID</th>
			}
			<th>Labels</th>
			@if (statusEnabled) {
				<th>Status</th>
			}
			@for (licentieRol of licenties; track licentieRol) {
				@if ($first && $last && licentieRol===LicentieRol.BESTUUR) {
					<th>Licenties in gebruik</th>
				} @else {
					<th>{{getLicentieRolLabel(licentieRol)}}</th>
				}
			}
		</tr>
	</thead>
	<tbody>
		@for (instelling of instellingen; track instelling.id) {
			@if (getStatus(instelling); as status) {
				<tr [class.inactief]="!instelling.actief || status.disabled" (click)="instellingClick.emit(instelling)">
					@if (checkboxEnabled) {
						<td class="checkbox">
							<app-checkbox [enabled]="!status.disabled" [value]="status.checked"> </app-checkbox>
						</td>
					}
					<td [class.status]="status.instellingETLStatus && etlIconStatussen.includes(status.instellingETLStatus)">
						<div class="naam">
							<span>
								{{ instelling.naam }}
							</span>
							@switch (status.instellingETLStatus) {
								@case (InstellingETLStatus.GEFAALD) {
									<div class="svg-error"></div>
								}
								@case (InstellingETLStatus.BEZIG) {
									<div class="svg-bezig"></div>
								}
								@case (InstellingETLStatus.GEPLAND) {
									<div class="svg-gepland"></div>
								}
							}
						</div>
					</td>
					@if (uuidEnabled) {
						<td>{{ instelling.uuid }}</td>
					}
					<td>
						<app-instelling-labels [instelling]="instelling" [toonBestuur]="toonBestuurLabel"></app-instelling-labels>
					</td>
					@if (statusEnabled) {
						<td>
							<span>
								{{ instelling.actief ? "Actief" : "Gedeactiveerd" }}
							</span>
						</td>
					}
					@for (licentieRol of licenties; track licentieRol) {
						<td>
							<span>
								{{ getLicenties(instelling, licentieRol) }}
							</span>
						</td>
					}
				</tr>
			}
		}
	</tbody>
	<tfoot>
		@if (totaalEnabled) {
			<tr>
				@if (checkboxEnabled) {
					<td></td>
				}
				<td [class.after-checkbox]="checkboxEnabled">Totaal</td>
				@if (uuidEnabled) {
					<td></td>
				}
				<td></td>
				@if (statusEnabled) {
					<td></td>
				}
				@for (licentieRol of licenties; track licentieRol) {
					<td>{{ getTotaalLicenties(licentieRol) }}</td>
				}
			</tr>
		}
	</tfoot>
</table>

import { Component } from '@angular/core';
import { HistoryService } from '@cumlaude/shared-services';
import { BestuurSettingsComponent } from './bestuur-settings.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';

@Component({
	selector: 'app-bestuur-toevoegen-page',
	templateUrl: './bestuur-toevoegen-page.component.html',
	styleUrl: './bestuur-page.component.scss',
	imports: [ButtonComponent, BestuurSettingsComponent],
})
export class BestuurToevoegenPageComponent {
	constructor(private historyService: HistoryService) {}

	goBack() {
		this.historyService.back();
	}
}

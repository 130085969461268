import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CumLaudeLicentieInfo, LicentieData, RCumLaudeModule, RInstelling, RInstellingAdditionalObjectKey } from '@cumlaude/service-contract';
import { firstValueFrom } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { sumBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { intValueFromInputElement } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-licenties-card',
	templateUrl: './licenties-card.component.html',
	styleUrl: './licenties-card.component.scss',
})
export class LicentiesCardComponent implements OnChanges {
	@Input()
	instelling!: RInstelling;

	licentieInfo!: CumLaudeLicentieInfo;
	licentiesTotaal!: LicentieData;

	RCumLaudeModule = RCumLaudeModule;

	@Output()
	instellingChanged = new EventEmitter<void>();

	constructor(
		private restService: RestService,
		private toastr: ToastrService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!('instelling' in changes)) return;
		this.licentieInfo = this.instelling.additionalObjects![RInstellingAdditionalObjectKey.LICENTIES];
		this.licentiesTotaal = {
			inGebruik: sumBy([this.licentieInfo.schoolbreedTeam, this.licentieInfo.sectie, this.licentieInfo.docentMentor], 'inGebruik'),
			totaal: sumBy([this.licentieInfo.schoolbreedTeam, this.licentieInfo.sectie, this.licentieInfo.docentMentor], 'totaal'),
		};
	}

	async change(key: 'licentiesSchoolbreedTeam' | 'licentiesSectie', $event: Event) {
		const value = intValueFromInputElement($event);
		if (value !== this.instelling[key]) {
			this.instelling[key] = value;
			await this.updateInstelling();
		}
	}

	isCumLaudeActief() {
		return this.instelling.actief && this.instelling.cumlaudeActief;
	}

	private async updateInstelling() {
		try {
			await firstValueFrom(this.restService.putInstelling(this.instelling));
			this.instellingChanged.emit();
		} catch (err: any) {
			this.toastr.error(`Er ging iets fout bij het opslaan van de instelling: ${err.error}`);
		}
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RCumLaudeModule, RInstelling, RVestiging } from '@cumlaude/service-contract';
import { ToastrService } from 'ngx-toastr';
import { Observable, firstValueFrom } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';

@Component({
	selector: 'app-modules-card',
	templateUrl: './modules-card.component.html',
	styleUrl: './modules-card.component.scss',
	imports: [AsyncPipe, TitleCasePipe, CheckboxComponent],
})
export class ModulesCardComponent implements OnInit {
	@Input()
	instelling!: RInstelling;

	@Output()
	instellingChanged = new EventEmitter<void>();

	vestigingen$!: Observable<RVestiging[]>;

	cumlaudeModules: RCumLaudeModule[] = Object.values(RCumLaudeModule);

	constructor(
		private restService: RestService,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.vestigingen$ = this.restService.getVestigingen({ instelling: this.instelling });
	}

	async setCumlaudeActief(vestiging: RVestiging, actief: boolean) {
		vestiging.cumlaudeActief = actief;
		await this.saveVestiging(vestiging);
	}

	async setModuleActief(vestiging: RVestiging, module: RCumLaudeModule, actief: boolean) {
		const idx = vestiging.modules.indexOf(module);

		if (idx >= 0 && !actief) vestiging.modules.splice(idx, 1);
		else if (idx < 0 && actief) vestiging.modules.push(module);
		else return; // niets veranderd

		await this.saveVestiging(vestiging);
	}

	private async saveVestiging(vestiging: RVestiging) {
		try {
			await firstValueFrom(this.restService.putVestiging(vestiging));
			this.instellingChanged.emit();
		} catch (err: any) {
			this.toastr.error(`Er ging iets fout bij het opslaan van de vestiging: ${err.error}`);
		}
	}

	async changeBestuurEnabled(instelling: RInstelling, bestuurEnabled: boolean) {
		try {
			await firstValueFrom(this.restService.putInstelling({ ...instelling, bestuurEnabled }));
		} catch (err: any) {
			this.toastr.error(`Er ging iets fout bij het opslaan van de instelling: ${err.error}`);
		}
	}
}
